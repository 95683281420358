<template>
  <div class="clearfix">
    <a-upload
      list-type="picture-card"
      :showUploadList="true"
      :file-list="fileList"
      accept=".jpg"
      @preview="handlePreview"
      @change="handleChange"
      :remove="remove"
      :customRequest="handleUpload"
    >
      <div v-if="fileList.length < 9">
        <a-icon type="plus" />
        <div class="ant-upload-text">最多上传9张图片</div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import cookieUtil from "@/common/cookie-util";
import api from "./api";
import { axiosInstance } from "@/common/request";
export default {
  name: "iamgeUpload",
  props: ["businessId"],
  data() {
    return {
      api,
      previewVisible: false,
      previewImage: "",
      showImage: false,
      fileId: "",
      uploadData: {
        fileBusinessMiodular: "0",
        file: "",
      },
      baseUrl: axiosInstance.defaults.baseURL,
      fileList: [],
      fileListAll: [],
      headers: {
        authorization: cookieUtil.getToken(),
      },
    };
  },
  watch: {
    businessId: {
      handler(val) {
        if (val) {
          this.getFileList(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.businessId) {
      this.getFileList(this.businessId);
    }
  },
  methods: {
    // 文件上传
    async handleUpload({ file }) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileBusinessMiodular", "0");
      const res = await api.uploadFile(formData);
      if (res.status === 200) {
        this.$message.success("上传成功");
        const fileDetails = res.data.fileDetails;
        const uploadedFile = {
          uid: file.uid,
          name: file.name,
          status: "done",
          url: fileDetails.url, // 更新文件对象的url属性
          response: { data: { id: fileDetails.id } },
        };
        console.log(fileDetails);
        this.fileList.push(uploadedFile); // 添加上传成功的文件对象到fileList数组中
        this.fileId = fileDetails.id;
      } else {
        this.$message.error("上传失败");
      }
    },
    //获取回显图片
    async getFileList(businessId) {
      const res = await api.getByBiz(businessId);
      console.log(res, "92");
      this.fileList = res.data.map((res) => {
        return { uid: res.id, name: res.fileName, url: res.url };
      });
    },
    // 获取图片的Base64信息
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // 取消上传图片
    handleCancel() {
      this.previewVisible = false;
    },
    // 点击文件或预览图标时的回调
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      // 点击眼睛的效果
      this.previewVisible = true;
    },
    async remove(file) {
      console.log(file, "121");
      this.fileListAll.push(file);
      const index = this.fileList.findIndex((item) => item.uid === file.uid);
      if (index !== -1) {
        this.fileList.splice(index, 1); // 从 fileList 中删除对应的文件对象
      }
      console.log(this.fileListAll,'134');
      return this.fileList, this.fileListAll;
    },
    // 文件改变时的回调(上传文件调用的回调)
    handleChange({ file, fileList }) {
      console.log(file);
    },
    getFile() {
      return this.fileList;
    },
    getFileListAll() {
      return this.fileListAll;
    },
    // 清空图片列表数据
    removeFileList() {
      this.fileList = [];
      this.fileListAll = [];
    },
  },
};
</script>